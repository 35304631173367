
.link-01 {
    color: $font-color-main;

    @include hover-focus() { color: $color-brand-01; }
}

.link-02 {
    color: $font-color-white;

    @include hover-focus() { color: $font-color-main; }
}

.link-03 {
    color: $font-color-03;

    @include hover-focus() { color: $font-color-white; }
}

.hover-primary {
    @include hover-focus() { color: $color-brand-01; }
}

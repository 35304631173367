@import "variables";

.form-control,
.ngx-datepicker-input {
    width: 100%;
    height: $height-control;
    padding: 0 10px;
    border: $border;
    border-radius: $border-radius;
    color: $font-color-main;
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;

    &:focus {
        color: inherit;
        border-color: $color-ui-border-focus;
        outline: 0;
        box-shadow: $box-shadow;
    }

    &::placeholder {
        color: $font-color-04;
    }

    &:disabled,
    &[readonly] {
        background-color: $color-ui-background;
    }

    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $font-color-main;
    }
}

.ngx-datepicker-input {
    width: 100% !important;
    border: $border !important;
    font-size: 13px !important;

    &[readonly] {
        background-color: $white;
    }
}

.ngx-datepicker-calendar-container {
    z-index: 1000;

    .is-selected {
        background: $color-brand-01 !important;
    }
}

textarea.form-control {
    min-height: $height-base;
}

.form-check,
.form-check-input,
.form-check-label {
    cursor: pointer;
}

.form-group {
    margin-bottom: 20px;
}

.form-fieldset {
    background-color: #fff;
    border: $border;
    padding: 20px 25px 25px;

    @include border-radius();

    .form-group:last-child {
        margin-bottom: 0;
    }

    legend {
        width: auto;
        background-color: inherit;
        padding: 0 10px;
        position: relative;
        margin-left: -10px;
        text-transform: uppercase;
        font-weight: $font-weight-semibold;
        font-size: inherit;
        color: $gray-900;
        letter-spacing: 0.5;
        margin-bottom: 0;
    }
}

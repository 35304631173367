
.activity {
    margin: 0;
    padding: 0;
    list-style: none;
}

.activity-item {
    display: flex;
    padding: 5px 0 10px;
    position: relative;
    min-height: 70px;

    &::before {
        content: '';
        position: absolute;
        top: 50px;
        bottom: 0;
        left: 20px;
        border-left: 2px dotted $color-ui-gray;
    }

    &:last-child {
        padding-bottom: 0;
        min-height: inherit;
    }
}

.activity-icon {
    width: 40px;
    height: 40px;
    background-color: $color-ui-background;

    @include border-radius(100%);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    color: $font-color-03;

    svg {
        width: 20px;
        height: 20px;
    }
}

.activity-body {
    margin-left: 15px;
    flex: 1;
    color: darken($font-color-03, 15%);

    strong {
        color: $font-color-white;
        font-weight: $font-weight-medium;
    }

    small {
        font-size: 11px;
    }
}

.irs--flat {
    .irs-min,
    .irs-max {
        color: $font-color-03;
        background-color: $color-ui-background;

        @include border-radius();
    }

    .irs-from,
    .irs-to,
    .irs-single {
        background-color: $color-brand-01;

        @include border-radius();

        &::before {
            border-top-color: $color-brand-01;
        }
    }

    .irs-line {
        background-color: $color-ui-border;

        @include border-radius();
    }

    .irs-bar {
        background-color: $color-brand-01;
    }

    .irs-bar--single {
        @include border-left-radius($border-radius);
    }

    .irs-handle {
        > i:first-child {
            background-color: darken($color-brand-01, 5%);
        }

        &:hover,
        &:focus,
        &:active {
            cursor: w-resize;

            > i:first-child {
                background-color: darken($color-brand-01, 7%);
            }
        }

        &.state_hover {
            &,
            &:hover,
            &:focus,
            &:active {
                > i:first-child {
                    background-color: darken($color-brand-01, 7%);
                }
            }
        }
    }

    .irs-grid-text {
        color: $font-color-03;
    }

    .irs-grid-pol {
        background-color: $color-ui-border;
    }
}

// round skin
.irs--round {
    .irs-min,
    .irs-max,
    .irs-from,
    .irs-to {
        font-size: 10px;

        @include border-radius();
    }

    .irs-min,
    .irs-max {
        background-color: $color-ui-background;
        color: $font-color-03;
    }

    .irs-grid-text {
        font-size: 10px;
        color: $font-color-03;
    }

    .irs-grid-pol {
        background-color: $color-ui-border;
    }
}

// square skin
.irs--square {
    .irs-line,
    .irs-grid-pol {
        background-color: $color-ui-background;
    }

    .irs-min,
    .irs-max {
        background-color: $color-ui-background;
        font-size: 10px;
        color: $font-color-03;
    }

    .irs-grid-text {
        font-size: 10px;
        color: $font-color-03;
    }

    .irs-from,
    .irs-to {
        background-color: $color-inverse-01;
        font-size: 10px;
    }

    .irs-handle {
        border-color: $color-inverse-01;
    }

    .irs-bar {
        background-color: $color-inverse-01;
    }
}

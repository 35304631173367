@import "variables";

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;

    &:focus,
    &:active {
        box-shadow: none;
    }

    i {
        margin-right: 0.3rem;
    }

    svg {
        width: 14px;
        height: 14px;
        stroke-width: 2.5px;
        margin-top: -2px;
    }

    &.active-primary {
        &,
        &:hover,
        &:focus {
            background-color: #fff;
            border-color: $color-brand-01;
            color: $color-brand-01;
        }
    }
}

.btn-default {
    border: $border;
    background: $white;
}

.btn-sm {
    font-size: 13px;
    padding-left: 15px;
    padding-right: 15px;
}

.btn-xs {
    height: auto;
    padding: 1px 0.4rem 0;
    font-size: 0.75rem;
    line-height: 1.2;
    border-radius: 0.15rem;

    @include border-radius();
}

.btn-lg {
    font-size: $font-size-base;

    @include border-radius();
}

.btn-light {
    background-color: $color-ui-background;
    border-color: darken($color-ui-background, 7%);
    color: $font-color-03;

    @include hover-focus() {
        background-color: $color-ui-background;
        border-color: darken($color-ui-background, 7%);
    }
}

.btn-outline-light {
    border-color: $gray-400;
    color: $gray-600;

    &:hover,
    &:focus,
    &:active {
        background-color: $gray-400;
        border-color: $gray-400;
    }
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
    background-color: $gray-300;
    border-color: $gray-400;
}

// White button variant
.btn-white {
    background-color: #fff;
    border-color: $color-ui-border;
    color: $font-color-main;

    @include hover() {
        border-color: $color-ui-gray;
    }
}

// Icon only button variant
.btn-icon {
    padding-left: 10px;
    padding-right: 10px;

    &.btn-sm {
        padding-left: 8px;
        padding-right: 8px;
    }

    &.btn-xs {
        padding-left: 6px;
        padding-right: 6px;
    }

    svg {
        margin: 0;
    }
}

// Brand buttons
.btn-brand-01 {
    background-color: $color-brand-01;
    border-color: $color-brand-01;
    color: $font-color-button;

    @include hover-focus() {
        background-color: darken($color-brand-01, 10%);
        border-color: darken($color-brand-01, 10%);
        color: #fff;
    }
}

// Uppercase button variant
.btn-uppercase {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 1.75;

    &.btn-sm,
    &.btn-xs {
        font-size: 11px;
        line-height: 1.773;
    }
}

@import "variables";

.dropdown {
    display: flex;
    justify-content: center;

    &.show {
        .dropdown-toggle {
            &.btn-brand-01 {
                background: darken($color-brand-01, 10%);
                box-shadow: darken($color-brand-01, 10%);
            }
            &.btn-white {
                border-color: $color-ui-border;
            }
        }
    }
}

.dropdown-menu {
    border-color: rgba($color-ui-dark, 0.27);
    box-shadow: 0 0 8px 2px rgba($gray-900, 0.04);
    padding: 5px;
    color: $font-color-main;
}

.dropdown-item {
    padding: 6px 15px;

    @include border-radius();
    @include transition($transition-base);

    @include hover-focus() {
        background-color: $color-ui-background;
    }

    &.active {
        background-color: $color-brand-01;
    }
}

.dropdown-header {
    padding-left: 15px;
    padding-right: 15px;
}

.dropdown-divider {
    margin: 2px 15px;
}

.dropdown-toggle {
    min-height: 22px;
    font-size: 10px;
}

.custom-control {
    min-height: inherit;
}

.custom-checkbox,
.custom-radio,
.custom-switch {
    .custom-control-label {
        user-select: none;
        transition: left 0.2s linear;


        &::before,
        &::after {
            top: 44%;
            transform: translateY(-50%);
        }

        // &::after {
        //     content: "\f00c";
        //     font-family: "Font Awesome 5 Free";
        //     font-weight: 900;
        //     color: #FFF;
        // }
        

        &:empty {
            height: 20px;

            &::before,
            &::after {
                left: -16px;
            }
        }
    }

    // Custom Checkbox and Radios
    .custom-control-input:disabled:checked {
        ~ .custom-control-label::before {
            border-color: rgba($blue, 0.2);
        }
    }

    // Custom Switch
    .custom-control-input:checked {
        ~ .custom-control-label::after {
            transform: translate(0rem, -50%);
            left: calc(-2.25rem + 13px);
        }
    }
}

// Custom Select
.custom-select {
    height: 38px;
    border-color: $color-ui-border;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 13px;

    &:focus {
        border-color: $color-ui-border-focus;
        box-shadow: $box-shadow;
    }

    &:invalid {
        color: $gray-500;
    }

    option {
        color: $font-color-main;
    }
}

// Custom File Browser
.custom-file-label {
    &,
    &::after {
        display: flex;
        align-items: center;
    }

    &::after {
        background-color: $color-ui-gray;
    }
}

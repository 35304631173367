
.divider-text {
    position: relative;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: $font-color-03;
    font-size: 10px;
    font-weight: 500;
    font-family: $font-family-system;
    letter-spacing: 0.5px;
    margin: 15px 0;

    &::before,
    &::after {
        content: '';
        display: block;
        flex: 1;
        height: 1px;
        background-color: $color-ui-background;
    }

    &::before { margin-right: 10px; }
    &::after { margin-left: 10px; }
}

.divider-vertical {
    flex-direction: column;
    margin: 0 15px;

    &::before,
    &::after {
        height: auto;
        width: 1px;
    }

    &::before {
        margin-right: 0;
        margin-bottom: 10px;
    }

    &::after {
        margin-left: 0;
        margin-top: 10px;
    }
}

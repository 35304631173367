
$gray-50:                           #fafbfc;
$gray-100:                          #f4f5f8;
$gray-200:                          #e3e7ed;
$gray-300:                          #cdd4e0;
$gray-400:                          #b4bdce;
$gray-500:                          #97a3b9;
$gray-600:                          #7987a1;
$gray-700:                          #596882;
$gray-800:                          #3b4863;
$gray-900:                          #1c273c;

$blue:                              $color-brand-01;
$green:                             #10b759;
$teal:                              #0cc;
$cyan:                              #00b8d4;
$pink:                              #f10075;
$indigo:                            #5b47fb;

// Buttons + Forms
$btn-focus-box-shadow:              none;
$btn-active-box-shadow:             none;

$input-btn-padding-y:               0.46875rem;
$input-btn-padding-y-sm:            0.391rem;
$input-btn-padding-y-lg:            0.6rem;
$input-btn-padding-x:               0.625rem;

$input-btn-font-size:               0.875rem;
$input-btn-font-size-sm:            0.8125rem;

// Buttons
$btn-padding-x:                     0.9375rem;

// Card
$card-border-color:                 $color-ui-border;

// Close
$close-color:                       $font-color-white;

// Fonts
$font-size-base:                    0.875rem;

$headings-font-weight:              $font-weight-medium;
$headings-color:                    $font-color-main;
$headings-line-height:              1.25;
$headings-font-weight:              $font-weight-medium;

// Forms
$input-border-color:                $color-ui-gray;
$input-group-addon-bg:              $color-ui-gray;

// Grid
$grid-gutter-width:                 30px;

// Links
$link-color:                        $color-brand-01;
$link-hover-decoration:             none;

// Popover
$popover-border-color:              $color-ui-border;

/* ###### 9.1 Background  ###### */

// Brand background colors
.bg-brand-01 { background-color: $color-brand-01; }
.bg-brand-02 { background-color: $color-brand-02; }
.bg-brand-03 { background-color: $color-brand-03; }

.bg-ui-01 { background-color: $color-ui-gray; }
.bg-ui-02 { background-color: $color-ui-background; }
.bg-ui-03 { background-color: $color-ui-gray; }
.bg-ui-04 { background-color: $color-ui-dark; }

.bg-gray-50 { background-color: $gray-50; }
.bg-gray-100 { background-color: $gray-100; }
.bg-gray-200 { background-color: $gray-200; }
.bg-gray-300 { background-color: $gray-300; }
.bg-gray-400 { background-color: $gray-400; }
.bg-gray-500 { background-color: $gray-500; }
.bg-gray-600 { background-color: $gray-600; }
.bg-gray-700 { background-color: $gray-700; }
.bg-gray-800 { background-color: $gray-800; }
.bg-gray-900 { background-color: $gray-900; }

.bg-white-1 { background-color: rgba(#fff, 0.1); }
.bg-white-2 { background-color: rgba(#fff, 0.2); }
.bg-white-3 { background-color: rgba(#fff, 0.3); }
.bg-white-4 { background-color: rgba(#fff, 0.4); }
.bg-white-5 { background-color: rgba(#fff, 0.5); }
.bg-white-6 { background-color: rgba(#fff, 0.6); }
.bg-white-7 { background-color: rgba(#fff, 0.7); }
.bg-white-8 { background-color: rgba(#fff, 0.8); }
.bg-white-9 { background-color: rgba(#fff, 0.9); }

.bg-black-1 { background-color: rgba(#000, 0.1); }
.bg-black-2 { background-color: rgba(#000, 0.2); }
.bg-black-3 { background-color: rgba(#000, 0.3); }
.bg-black-4 { background-color: rgba(#000, 0.4); }
.bg-black-5 { background-color: rgba(#000, 0.5); }
.bg-black-6 { background-color: rgba(#000, 0.6); }
.bg-black-7 { background-color: rgba(#000, 0.7); }
.bg-black-8 { background-color: rgba(#000, 0.8); }
.bg-black-9 { background-color: rgba(#000, 0.9); }

.bg-gray-1 { background-color: rgba($gray-900, 0.1); }
.bg-gray-2 { background-color: rgba($gray-900, 0.2); }
.bg-gray-3 { background-color: rgba($gray-900, 0.3); }
.bg-gray-4 { background-color: rgba($gray-900, 0.4); }
.bg-gray-5 { background-color: rgba($gray-900, 0.5); }
.bg-gray-6 { background-color: rgba($gray-900, 0.6); }
.bg-gray-7 { background-color: rgba($gray-900, 0.7); }
.bg-gray-8 { background-color: rgba($gray-900, 0.8); }
.bg-gray-9 { background-color: rgba($gray-900, 0.9); }

// Light theme background colors
.bg-primary-light { background-color: rgba($blue, 0.15); }
.bg-success-light { background-color: rgba($green, 0.18); }
.bg-warning-light { background-color: rgba($orange, 0.18); }
.bg-pink-light { background-color: rgba($pink, 0.18); }
.bg-indigo-light { background-color: rgba($indigo, 0.18); }

// Extra background colors
.bg-indigo { background-color: $indigo; }
.bg-purple { background-color: $purple; }
.bg-pink { background-color: $pink; }
.bg-orange { background-color: $orange; }
.bg-teal { background-color: $teal; }
.bg-lightblue { background-color: #a5d7fd; }

.bg-transparent { background-color: transparent; }

// Brand background colors
.bg-facebook { background-color: #4064ac; }
.bg-twitter { background-color: #00a7e6; }

a,
button,
.btn {
    &.bg-facebook {
        @include hover-focus() { background-color: darken(#4064ac, 5%); }
    }

    &.bg-twitter {
        @include hover-focus() { background-color: darken(#00a7e6, 5%); }
    }
}

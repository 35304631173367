// Brand Colors
$color-brand-01: #F97F15;
$color-brand-02: #a9a9aa;
$color-brand-03: #7e7e7e;

// Colors set
$green: #32a63b;
$green-dark: #2e8030;
$green-light: #2ecc40;
$green-very-light: #c7eea4;
$blue: #1976d2;
$blue-light: #90caf9;
$orange: #ff851b;
$orange-light: #ffcc80;
$orange-very-light: #fff6e4;
$yellow: #ffcb04;
$red: red;
$red-dark: #6d0e00;
$black: #3c3c3c;
$gray: $color-brand-03;
$gray-light: #ccc;
$gray-very-light: #efefef;
$gray-dark: $color-brand-03;
$white: #fff;
$gray-extra-light: #fafafa;
$gray-dark-700:#596882;
// Inverse Colors
$color-inverse-01: $color-brand-01;
$color-inverse-02: $color-brand-01;

// Font Colors
$font-color-main: $black;
$font-color-link: $color-brand-01;
$font-color-headers: $black;
$font-color-error: $red-dark;
$font-color-button: $black;
$font-color-white: $white;

$font-color-03: #8392a5;
$font-color-04: #c0ccda;
$brand-family-base: 'Nunito', sans-serif !default;

// UI Colors
$color-ui-background: $gray-very-light;
$color-ui-border: $gray-light;
$color-ui-border-focus: $color-brand-01;
$color-ui-gray: $gray;
$color-ui-dark: $black;

$login-page-background: url('/assets/img/login-bg.jpg');

$calendar-past-day-bg: #fbfbfb;
$calendar-today-bg: $green-very-light;
$calendar-active-day-bg: $orange-very-light;

// Headers
$header-font-weight: 600;

// Navigation
$navigation-font-color: $font-color-main;
$navigation-border-bottom: 2px solid $color-brand-01;
$navigation-submenu-border-color: $color-brand-01;
$navigation-color-active-link: $color-brand-01;
$navigation-active-link-weight: false;
$navigation-arrow-background: $color-brand-01;
$navigation-arrow-offset: -11px;
$navigation-background: $white;
$navigation-font-size: 15px;
$navigation-font-weight: 500;
$navigation-submenu-border-top-width: 2px;
$navigation-submenu-offset: 102px;
$navigation-text-transform: false;
$navigation-submenu-font-size: 14px;

// Height
$height-base: 38px;
$height-header: 102px;
$height-header-mobile: 55px;
$height-footer: 46px;
$height-control: 38px;

// Width
$width-sidebar: 240px;

// Border
$border-radius: 0.25rem;
$border: solid 1px $color-ui-border;
$box-shadow: 0 0 3px 1px $color-ui-border-focus;

// Margin & padding
$base-padding: 0.75rem;

// Marker
$marker-icon-size: 16px;

// Fonts
$font-path: 'https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap';
$font-family-base: $brand-family-base;
$font-family-system: -apple-system, 'Nunito', sans-serif;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-size: 13px;

// Logo
@mixin logo() {
    background-image: url("/assets/img/logos/mamyito_logo.svg");
    width: 120px;
    height: 72px;
    flex-grow: 1;
}

@mixin logo-powered() {
    color: $font-color-button;
    position: absolute;
    bottom: 0;
}

@import 'variables';

@mixin toolbar() {
    margin-bottom: 0.75rem;
    display: flex;
    flex-direction: row;

    & > * {
        margin: 0 0.75rem 0 0;

        &:last-child {
            margin-right: 0;
        }
    }

    i-feather {
        position: relative;
        top: -1px;
    }

    button,
    a {
        flex-grow: 0;
        flex-shrink: 0;

        i-feather {
            top: 2px;
        }
    }

    .filter-container {
        flex-grow: 2;
    }
}

@mixin sidebar() {
    height: calc(100% - 25px);
    position: relative;
    top: 25px;

    [header] {
        .item {
            font-size: 12px;
            color: $font-color-main;
            padding: 13px 0;
            width: 100%;
            cursor: pointer;

            &.active {
                background: $white;
                opacity: 1;
                font-weight: bold;
            }

            span {
                display: block;
                font-weight: 300;
                text-transform: lowercase;
                font-size: 14px;
                margin-top: -4px;

                &.green {
                    color: $green;
                }

                &.red {
                    color: $red;
                }
            }
        }
    }

    [content] {
        height: 100%;
    }
}

@mixin datatable() {
    background-color: white;
    position: relative;
    color: #3f536e;
    margin-bottom: 0;

    tbody + tbody {
        border: 0;

        th,
        td {
            border: 0;
        }
    }

    tr {
        height: 0;
        cursor: pointer;

        td {
            vertical-align: middle;
            font-size: 13px;

            &.no-data {
                font-weight: bold;
                padding: 15px 0;
            }
        }

        th {
            font-size: 13px;
            padding-bottom: 11px;
        }
    }
}

@mixin spinner {
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;

    &.no-animate {
        animation: none;
    }

    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
}

@mixin loader() {
    i {
        -webkit-animation: spin 2s linear infinite;
        -moz-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;

        &.no-animate {
            animation: none;
        }
    }

    p {
        font-size: 11px;
        letter-spacing: 0.2px;
        margin-top: 10px;
        color: #3f536e;
    }

    &.error {
        color: $font-color-error;

        p {
            color: $font-color-error;
        }
    }

    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
}

@mixin runtime() {
    color: $green;
    font-weight: 600;
}

@mixin runtime-active-max() {
    color: $red-dark;
    font-weight: 600;
}

@mixin day-in-past() {
    background-color: $calendar-past-day-bg;
}

@mixin today() {
    background-color: $calendar-today-bg;
}

@mixin table-header() {
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $font-color-main;
    border-top: none;
    padding-top: 12px;
}

@mixin font-style-regular() {
    font-size: 12px;
    font-weight: $font-weight-normal;
    color: $font-color-main;
}

@mixin scroll-chrome {
    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track-piece {
        background: #eee;
    }

    ::-webkit-scrollbar-thumb {
        background: #9e9e9e;
    }
}

@mixin table-style {
    position: static;

    &__head {
        background: white;
        position: sticky;
        top: 0;
        z-index: 10;

        tr {
            height: 0;
            cursor: pointer;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                border-bottom: $border;
            }

            th {
                @include table-header;
            }
        }
    }

    &__body {
        th {
            border: 0;
        }

        tr {
            height: 0;
            cursor: pointer;
            font-weight: 300;
            color: #3f536e;

            &:last-of-type td {
                border: none;
            }

            &:hover {
                background: $color-ui-background;
            }

            td {
                overflow: hidden;
                vertical-align: middle;
                font-size: 12px;
                border-bottom: $border;
                border-top: none;

                &.no-data {
                    font-weight: bold;
                    padding: 15px 0;

                    .empty {
                        position: relative;
                        top: 2px;
                        left: 5px;
                    }
                }

                &.options-cell {
                    overflow: visible;

                    button {
                        font-size: 10px;
                        text-transform: uppercase;
                    }
                }

                .small-icon {
                    width: 15px;
                    height: 15px;
                    color: $font-color-main;
                    font-weight: bold;
                }
            }
        }

        .row-modified {
            td {
                color: $color-brand-01;
                background-color: rgba($color-brand-01, 0.2);
            }
        }
    }
}

@mixin form-style {
    padding: 40px 0 30px 0;



    .form-group {
        label {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            text-transform: uppercase;
            text-align: right;

            @include font-style-regular;

            h2,
            button {
                margin: 0 10px;
            }

            h2 {
                @include font-style-regular;

                font-size: 14px;
                font-weight: 500;
            }

        }
        .custom-select {
            &:disabled {
                background-color: $gray-extra-light;
                cursor: not-allowed;
            }
        }
    }

}

@mixin input {
    width: 100%;
    height: $height-control;
    padding: 0 10px;
    border: $border;
    border-radius: $border-radius;
    color: $font-color-main;
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;

    &:focus {
        color: inherit;
        border-color: $color-ui-border-focus;
        outline: 0;
        box-shadow: $box-shadow;
    }

    &::placeholder {
        color: $font-color-04;
    }

    &:disabled,
    &[readonly] {
        background-color: $color-ui-background;
    }

    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $font-color-main;
    }
}

@mixin card() {
    margin: 6px;
    border: $border;
}

@mixin row-modified() {
    color: $color-brand-01;
    background-color: rgba($color-brand-01, 0.2) !important;
}

// Accordion Variant
@mixin accordion-variant($color, $lighten-color) {
    .ui-accordion-header.ui-state-active {
        color: $color;
    }

    &.accordion-style1 {
        .ui-accordion-header.ui-state-active {
            border-color: $color;
            background-color: $lighten-color;
        }

        .ui-accordion-content.ui-accordion-content-active {
            border-color: $color;
            background-color: $lighten-color;
        }
    }

    &.accordion-style2 {
        .ui-accordion-header {
            background-color: $color;

            @include hover() {
                background-color: darken($color, 2%);
            }

            &.ui-state-active {
                background-color: $lighten-color;
                color: $color;
                border-color: $color;
            }
        }

        .ui-accordion-content {
            background-color: $lighten-color;
            border-color: $color;
        }
    }
}

// Marker Variations
@mixin marker-variant($color) {
    color: #fff;

    &.marker-icon {
        &::before {
            background-color: $color;
        }
    }

    &.marker-ribbon {
        background-color: $color;

        &::after {
            border-bottom-color: darken($color, 15%);
        }
    }
}

// $name = class name
// $perc = donut percentage value
// $size = size of donut
// $width = width of chart
// $base = thickness of donut
// $center = background color of the percentage
// $color = background color of donut hole
// $textColor = color of text in center and the percentage
// $textSize = size of text
@mixin donut-chart($name, $perc, $size, $width, $base, $center, $color, $textColor: $color, $textSize: 40px) {
    $color2: $color;
    $base2: $base;
    $deg: ($perc/100 * 360) + deg;
    $deg1: 90deg;
    $deg2: $deg;

    // If percentage is less than 50%
    @if $perc < 50 {
        $base: $color;
        $color: $base2;
        $color2: $base2;
        $deg1: ($perc / 100 * 360 + 90) + deg;
        $deg2: 0deg;
    }

    .az-donut-chart {
        &#{$name} {
            width: $size;
            height: $size;
            background: $base;

            .slice {
                &.one {
                    clip: rect(0 $size $size/2 0);
                    -webkit-transform: rotate($deg1);
                    transform: rotate($deg1);
                    background: $color;
                }

                &.two {
                    clip: rect(0 $size/2 $size 0);
                    -webkit-transform: rotate($deg2);
                    transform: rotate($deg2);
                    background: $color2;
                }
            }

            .chart-center {
                top: $width;
                left: $width;
                width: $size - ($width * 2);
                height: $size - ($width * 2);
                background: $center;

                span {
                    font-size: $textSize;
                    line-height: $size - ($width * 2);
                    color: $textColor;

                    &::after {
                        content: '#{$perc}%';
                    }
                }
            }
        }
    }
}

@mixin noselect() {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


@mixin punctuality-colors {
    &.green {
        background: $green;
    }
    &.red {
        background: $red;
    }
    &.blue {
        background: $blue;
    }
}

@mixin remove-lr-boder-table {
    &:first-child {
        border-left: transparent;
    }

    &:last-child {
        border-right: transparent;
    }
}

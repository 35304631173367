/* ###### 4.1 Datatables ###### */

table.dataTable {
    border: $border;

    &.no-footer {
        border-bottom-color: $color-ui-border;
    }

    thead th,
    thead td {
        border-top-width: 0;
        border-bottom-width: 0;
        padding: 8px 10px;
        font-weight: $font-weight-medium;
        font-size: inherit;
    }

    thead .sorting_asc,
    thead .sorting_desc {
        background-image: none;
        background-color: lighten($gray-200, 3%);
        position: relative;

        &::after {
            font-family: 'Ionicons', sans-serif;
            font-size: 11px;
            position: absolute;
            line-height: 0;
            top: 50%;
            right: 10px;
        }
    }

    thead .sorting_asc::after {
        content: '\f3d8';
    }

    thead .sorting_desc::after {
        content: '\f3d0';
    }

    thead .sorting {
        background-image: none;
        position: relative;

        &::before,
        &::after {
            font-family: 'Ionicons', sans-serif;
            font-size: 11px;
            position: absolute;
            line-height: 0;
            right: 10px;
        }

        &::before {
            content: '\f3d8';
            top: 40%;
        }

        &::after {
            content: '\f3d0';
            top: 60%;
        }
    }

    tbody td.sorting_1 {
        background-color: lighten($gray-200, 5%);
    }

    &.dtr-inline.collapsed {
        > tbody > tr[role='row'] > td:first-child::before {
            top: 9.5px;
            left: 7px;
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-width: 0;
            box-shadow: none;
            background-color: $gray-300;
            font-size: 14px;
            font-weight: $font-weight-bold;
            color: $gray-500;
        }
    }

    > tbody > tr.child {
        ul.dtr-details {
            display: block;
        }

        span.dtr-title {
            font-weight: $font-weight-medium;
        }
    }
}

.dataTables_wrapper {
    .dataTables_length {
        text-align: left;

        @include media-breakpoint-up(sm) {
            float: left;
        }

        label {
            display: block;
            margin-bottom: 15px;
        }

        .select2-container--default {
            margin-right: 5px;

            .select2-selection--single {
                height: 32px;
                border-color: $color-ui-border;

                @include border-radius();

                .select2-selection__rendered {
                    line-height: 32px;
                }

                .select2-selection__arrow {
                    height: 30px;

                    b {
                        border-top-color: $gray-500;
                    }
                }
            }
        }
    }

    .dataTables_filter {
        text-align: left;

        @include media-breakpoint-up(sm) {
            float: right;
            margin-top: 0;
        }

        label {
            display: block;
            margin-bottom: 15px;
        }

        input {
            margin-left: 0;
            border: $border;
            padding: 5px 10px;
            line-height: 1.539;
            color: $font-color-main;

            @include border-radius();

            width: 100%;

            @include media-breakpoint-up(sm) {
                width: auto;
            }

            &::placeholder {
                color: $gray-500;
            }
        }
    }

    .dataTables_info {
        margin-top: 15px;
        padding: 5px 0;
        text-align: left;

        @include media-breakpoint-up(sm) {
            float: left;
        }
    }

    .dataTables_paginate {
        padding-top: 0;
        margin-top: 15px;
        text-align: left;

        @include media-breakpoint-up(sm) {
            float: right;
        }

        .paginate_button {
            background-color: $gray-200;
            padding: 5px 10px;
            margin: 0;
            border: 0;

            @include border-radius(1px);
            @include transition($transition-base);

            + .paginate_button {
                margin-left: 3px;
            }

            &.disabled {
                &,
                &:hover,
                &:focus {
                    background-color: lighten($gray-200, 3%);
                    color: $gray-400 !important;
                }
            }

            &.previous {
                margin-right: 3px;
            }

            &.next {
                margin-left: 3px;
            }

            @include hover-focus() {
                border: 0;
                background-image: none;
                background-color: $gray-400;
                color: $font-color-main !important;
            }

            &.current {
                &,
                &:hover,
                &:focus {
                    border: 0;
                    background-image: none;
                    background-color: $color-brand-01;
                    color: #fff !important;
                }
            }
        }
    }
}


/* ###### ngx-datatable ###### */

.ngx-datatable {
    &.bootstrap {
        overflow: visible;
        border-right: $border;
        .datatable-body {
            font-weight: 300;
            color: #3f536e;

            datatable-selection {
                display: flex;
            }

            .datatable-row-wrapper {
                .datatable-body-row {
                    font-size: 12px;
                    font-weight: 300;
                    color: #3f536e;
                    cursor: pointer;
                    border-right: $border;

                    &:hover {
                        background-color: $color-ui-background !important;
                    }

                    &.datatable-row-even {
                        background: $white;
                    }
                }
            }

            .row-modified {
                &.datatable-row-even {
                    color: $color-brand-01;
                    background-color: rgba($color-brand-01, 0.2) !important;
                }

                color: $color-brand-01;
                background-color: rgba($color-brand-01, 0.2);
            }
        }

        .datatable-header-cell-label {
            font-weight: bold;
        }

        .datatable-footer {
            background: $white !important;
            color: $black !important;
            margin-top: -5px !important;

            .page-count {
                display: none;
            }

            .datatable-pager {
                a {
                    color: $gray-dark !important;
                }

                li {
                    &.active, &:hover {
                        a {
                            color: $white !important;
                        }
                    }
                }
            }
        }
    }
}

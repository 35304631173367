[content] {
    //position: relative;
    height: 100%;
}

.content-fixed {
    @include media-breakpoint-up(lg) {
        margin-top: 0;
    }
}

.content-header {
    background-color: #fff;
    height: $height-header - 5px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: $border;
    padding-left: $height-header + 5px;

    @include media-breakpoint-up(lg) {
        height: $height-header;
        padding: 0 25px;
    }

    .nav {
        @media (max-width: 575px) {
            flex-wrap: nowrap;
        }
    }

    .nav-link {
        padding: 0;
        color: rgba($font-color-white, 0.75);

        @include hover-focus() { color: $font-color-white; }

        + .nav-link {
            margin-left: 7px;

            @include media-breakpoint-up(sm) { margin-left: 10px; }
        }
    }

    svg {
        width: 18px;
        height: 18px;

        @include media-breakpoint-up(lg) {
            width: 20px;
            height: 20px;
        }

        &.feather-search { stroke-width: 2.8px; }
    }
}

.content-search {
    width: 250px;
    display: flex;
    align-items: center;

    svg {
        stroke-width: 2.5px;
        margin-right: 10px;
        color: $font-color-03;
    }

    .form-control {
        padding: 0;
        border-width: 0;
        background-color: transparent;

        ng &:focus {
            box-shadow: none;
            color: $font-color-white;
        }
    }
}

.content-body {
    position: relative;
    padding: 15px;
    height: calc(100% - #{$height-header - 6px});
    overflow-y: auto;

    @include media-breakpoint-up(sm) { padding: 20px; }

    @include media-breakpoint-up(lg) {
        padding: 25px;
        height: calc(100% - #{$height-header});
    }

    .container {
        margin-bottom: 25px;

        @include media-breakpoint-down(lg) { max-width: none; }
    }
}

.content-footer {
    font-size: 10px;
    font-family: $font-family-system;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    margin-top: 60px;
    padding-top: 20px;
    border-top: $border;
    color: darken($font-color-03, 12%);

    @include media-breakpoint-up(sm) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    @include media-breakpoint-up(lg) { padding-top: 25px; }

    @include media-breakpoint-up(xl) { padding-top: 30px; }

    a {
        padding: 0;
        color: $font-color-white;

        @include hover-focus() { color: $color-brand-01; }

        + .nav-link { margin-left: 25px; }
    }

    > div:last-child {
        margin-top: 15px;

        @include media-breakpoint-up(sm) { margin-top: 0; }
    }
}

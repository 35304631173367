.footer {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    height: $height-footer;
    font-size: 10px;
    font-family: $font-family-system;
    letter-spacing: 0.3px;
    padding: 15px 25px;
    background-color: $gray-50;
    color: darken($font-color-03, 12%);
    border-top: $border;
    text-transform: uppercase;

    a {
        padding: 0;
        color: $font-color-link;

        @include hover-focus() {
            color: darken( $font-color-link, 10%);
        }

        + .nav-link {
            margin-left: 25px;
        }
    }

    > div:last-child {
        margin-top: 15px;

        @include media-breakpoint-up(sm) {
            margin-top: 0;
        }
    }
}

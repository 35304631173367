/* ###### 4.10 Select2 ###### */
$input-color: #000;

.select2-results__option {
    border-radius: 0;
    margin-bottom: 1px;
    font-size: inherit;
    padding: 6px 10px;
}

.select2-container--default {
    .select2-selection--single {
        background-color: #fff;
        border-color: $input-border-color;

        @include border-radius();

        height: $height-base; //$input-height;
        outline: none;

        .select2-selection__rendered {
            color: $input-color;
            line-height: $input-height;
            padding-left: $input-btn-padding-x;
            height: 100%;
            display: flex;
            align-items: center;
        }

        .select2-selection__placeholder {
            color: $input-placeholder-color;
        }

        .select2-selection__arrow {
            width: 30px;
            height: $height-base; //$input-height;
            line-height: $input-height;

            b {
                margin-top: -3px;
            }
        }

        .select2-selection__clear {
            font-size: 20px;
            font-weight: $font-weight-base;
            order: 2;
            margin-left: auto;
            margin-right: 5px;
            color: $font-color-03;
            position: relative;
            z-index: 100;

            @include hover() {
                color: $font-color-white;
            }
        }
    }

    .select2-selection--multiple {
        background-color: $input-bg;
        border-color: $input-border-color;
        border-radius: 2px;
        min-height: $height-base;
        outline: none;

        .select2-selection__rendered {
            padding: 0 4px;
            display: block;
        }

        .select2-selection__choice {
            position: relative;
            margin-top: 4px;
            margin-right: 4px;
            padding: 3px 10px 3px 20px;
            border-color: transparent;
            border-radius: 1px;
            background-color: $color-brand-01;
            color: #fff;
            font-size: 13px;
            line-height: 1.45;
        }

        .select2-selection__choice__remove {
            color: #fff;
            opacity: 0.5;
            font-size: 14px;
            font-weight: $font-weight-base;
            display: inline-block;
            position: absolute;
            top: 4px;
            left: 7px;
            line-height: 1.2;
        }
    }

    &.select2-container--focus .select2-selection--multiple {
        border-color: $input-border-color;
    }

    .select2-search--dropdown .select2-search__field {
        border-color: $input-border-color;
        border-radius: 0;
        padding: 5px 10px;

        &::placeholder {
            color: $font-color-03;
        }
    }

    .select2-results__option[aria-selected='true'] {
        background-color: lighten($body-bg, 5%);
    }

    .select2-results__option--highlighted[aria-selected] {
        background-color: $color-brand-01;
    }

    .select2-results > .select2-results__options {
        margin: 4px;
    }

    .select2-search--inline .select2-search__field {
        margin-top: 6px;
        line-height: 26px;
        padding-left: 10px;
        opacity: 1;

        &::placeholder {
            color: $font-color-03;
            font-weight: $font-weight-base;
            opacity: 1;
        }
    }

    &.select2-container--disabled {
        .select2-selection--single {
            background-color: $color-ui-gray;
        }

        .select2-selection__choice {
            padding-left: 10px;
            background-color: $gray-500;

            .select2-selection__choice__remove {
                display: none;
            }
        }
    }
}

.select2-container--open {
    font-size: 14px;

    .select2-selection--single,
    .select2-selection--multiple {
        background-color: #fff;
        border-color: $input-border-color;
    }

    .select2-dropdown--above {
        @include border-top-radius(0);
    }

    .select2-dropdown--below {
        @include border-bottom-radius(0);

        top: 0; //$input-height - 1.15;
    }
}

.select2-dropdown {
    border-color: $input-border-color;
    z-index: 200;
}

.select2-search--dropdown {
    padding-bottom: 0;
}

// Alert State
.has-success .select2-container--default .select2-selection--single {
    border-color: theme-color('success');
}

.has-warning .select2-container--default .select2-selection--single {
    border-color: theme-color('warning');
}

.has-danger .select2-container--default .select2-selection--single {
    border-color: theme-color('danger');
}

// Size
.select2-xs + .select2-container {
    font-size: 12px;
}

.select2-dropdown-xs .select2-results__option {
    font-size: 12px;
}

.select2-sm + .select2-container {
    font-size: 14px;
}

.select2-dropdown-sm .select2-results__option {
    font-size: 14px;
}

// Border
.select2-bd-0 + .select2-container--default .select2-selection--single {
    border-width: 0;
}

.bg-gray + .select2-container--default .select2-selection--single {
    background-color: lighten($gray-800, 5%);

    .select2-selection__rendered {
        color: $gray-300;
    }
}

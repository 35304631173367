@import 'brand/brand';

.marker {
    background-color: lighten($color-ui-background, 4%);
    color: $font-color-main;
    padding: 5px 10px;
    font-size: 10px;
    font-weight: 700;
    font-family: $font-family-system;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.marker-icon {
    position: relative;
    overflow: hidden;
    display: flex;
    width: 45px;
    height: 45px;

    &::before {
        content: '';
        position: absolute;
        top: -70%;
        left: -70%;
        width: 140%;
        height: 140%;
        background-color: lighten($color-ui-background, 4%);
        transform: rotate(45deg);
    }

    svg {
        width: $marker-icon-size;
        height: $marker-icon-size;
        margin-top: 6px;
        margin-left: 6px;
        position: relative;
        z-index: 1;
    }

    &.marker-top-right {
        &::before {
            left: auto;
            right: -70%;
        }

        svg {
            margin-left: auto;
            margin-right: 6px;
        }
    }

    &.marker-bottom-left {
        &::before {
            top: auto;
            bottom: -70%;
        }

        svg {
            margin-top: auto;
            margin-bottom: 6px;
        }
    }

    &.marker-bottom-right {
        &::before {
            top: auto;
            bottom: -70%;
            left: auto;
            right: -70%;
        }

        svg {
            margin-top: auto;
            margin-left: auto;
            margin-bottom: 6px;
            margin-right: 6px;
        }
    }
}

.marker-ribbon {
    margin-left: -10px;
    box-shadow: 1px 1px 1px rgba($gray-900, 0.16);

    &::after {
        content: '';
        position: absolute;
        bottom: -6px;
        left: 0;
        border-bottom: 7px solid $gray-400;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        transform: rotate(45deg);
    }

    &.marker-top-right {
        margin-left: 0;
        margin-right: -10px;

        &::after {
            left: auto;
            right: 0;
            transform: rotate(-45deg);
        }
    }
}

.marker-primary { @include marker-variant($blue); }
.marker-secondary { @include marker-variant($gray-500); }
.marker-success { @include marker-variant($green); }
.marker-danger { @include marker-variant($red); }
.marker-warning { @include marker-variant($yellow); }
.marker-info { @include marker-variant($cyan); }
.marker-dark { @include marker-variant($gray-900); }

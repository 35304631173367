@import url("https://use.fontawesome.com/releases/v5.2.0/css/all.css");
@import "brand/brand";
@import "mixins";
@import "dashforge";

// regular style toast
@import "ngx-toastr/toastr";

@if $font-path {
    @import url($font-path);
}

body {
    font-family: $font-family-base;
    font-size: 14px;
    color: $font-color-main;

    .card {
        @include card();

        form {
            label {
                font-size: 15px;
                font-weight: 300;
                line-height: 1.27;
                color: #000;
            }
        }
    }

    [toolbar] {
        h4 {
            i-feather {
                display: none;
            }
        }
    }

    .ngx-toastr {
        max-width: 80vw;
        min-width: 50vw;
    }

    .today-highlight {
        background-color: #f2f2f2 !important; /* Czerwone tło */
        color: #000 !important; /* Czarny tekst */
        border-radius: 50%; /* Zaokrąglone krawędzie */
    }

    .theme-brand {
        .bs-datepicker-head {
            background-color: $color-brand-01; /* Jasnoczerwone tło */
            color: $color-brand-01; /* Ciemnoczerwony tekst */
            border: 1px solid $color-brand-01; /* Czerwone obramowanie */
        }

        .bs-datepicker-body table td span.selected {
            background-color: $color-brand-01;
        }
    }

    .dndDragover {
        // border: 1px dashed $color-brand-01;
        // box-sizing: content-box;
        opacity: 0.4;
        background-color: $color-brand-01 !important;
    }

    .shift-menu,
    .sidebar-menu {
        display: flex;
        justify-content: space-around;
        border-bottom: $border;
        flex-wrap: wrap;

        .shift {
            @include font-style-regular();

            padding: 13px 10px;
            opacity: 0.7;
            cursor: pointer;
            text-transform: uppercase;

            &.active {
                background: $white;
                opacity: 1;
                font-weight: bold;
            }

            &.MORNING {
                display: none;
            }
        }
    }

    angular2-multiselect {
        min-width: 300px;
        .list-area {
            min-width: 250px;
        }
    }

    notifier-notification {
        width: 100%;
        text-align: center;
    }

    .notifier__notification-message {
        text-align: center;
        width: 100%;
    }

    .empty-row {
        text-align: center;
        font-weight: bold;
        padding: 100px 0;
    }

    .modal {
        @include font-style-regular;

        button {
            display: inline;
            text-transform: uppercase;
            margin: auto 4px;
        }
    }
    .btn-outline-secondary {
        border: none;
    }

    .form-control:disabled,
    .form-control[readonly] {
        background-color: $gray-extra-light;
        cursor: not-allowed;
    }

    .custom-select:disabled {
        background-color: $gray-extra-light;
        cursor: not-allowed;
    }

    .form-control.ng-pristine.ng-invalid.ng-touched {
        border-color: red !important;
    }

    .form-control.ng-touched.ng-dirty.ng-valid {
        border-color: $green-dark;
    }

    .custom-control-input {
        left: -4px;
        user-select: none;
    }
    datatable-body-cell {
        word-break: break-all;
    }
}

h1,
h2,
h3,
h4,
h5 {
    color: $font-color-headers;
    font-weight: $header-font-weight;
}

h1 {
    font-size: 1.75rem;
}

h3 {
    font-size: 1rem;
}

h2 {
    font-size: 1rem;
}

h4 {
    font-size: 20px;
    text-transform: uppercase;
}

h5 {
    font-size: 1rem;
    font-weight: 400;
}

.table-hover {
    tbody {
        tr {
            &:hover {
                color: $font-color-main;
            }
        }
    }
}

.load-spinner {
    height: calc(100% - #{$height-footer} - #{$height-header});
    align-items: center;
    display: flex;
    justify-content: center;

    i {
        -webkit-animation: spin 2s linear infinite;
        -moz-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
        margin-right: 5px;

        &.no-animate {
            animation: none;
        }
    }

    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    p {
        @include font-style-regular;
    }

    &.error {
        color: $font-color-error;

        p {
            color: $font-color-error;
        }
    }
}

.empty-data {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
    width: 100%;
    color: $red-dark;
}

.editable-window {
    padding: 1px 1px 34px 1px;

    .action {
        color: $gray-dark;

        &.btn-link {
            padding: 2px !important;
        }
    }

    h3 {
        font-size: 1.4em;
        line-height: 2em;
        font-weight: 600;
        padding: 10px 0;
        margin: 0;

        span {
            font-weight: 400;
            font-size: 12px;
            line-height: 1.4em;
        }
    }

    .slot-limits {
        flex-direction: column;

        &__container {
            padding-left: 20px;
        }

        &__hour {
            flex-grow: 1;
            font-weight: 300;
        }

        &__limit {
            text-align: center;
            min-width: 60px;
        }
    }

    .modify-value {
        border-radius: 4px;
        background-color: #fff;

        @include font-style-regular();

        font-weight: 500;
        min-width: 60px;
        display: inline-block;
        text-align: center;
        padding: 0;

        &.cut-off {
            margin-left: 17px;
        }

        &--slots {
            display: flex;
            text-align: left;
        }
    }

    .editable-content {
        @include font-style-regular();

        label {
            // width: 90px;
            // height: 25px;

            &.shift-switcher {
                margin-top: -7px;
                margin-bottom: -15px;

                @include font-style-regular();

                font-weight: bold;
            }
        }
    }

    .runtime {
        @include runtime();

        & > label {
            @include runtime();
        }
    }
}

.saving editor-window .overlay {
    display: flex;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $green-dark;
    z-index: 1;
    align-items: center;
    justify-content: center;
}

editor-window {
    position: relative;
    display: block;
    border: 1px solid $color-brand-01;

    .overlay {
        display: none;
    }

    h3.title {
        font-size: 1em;
        color: $white;
        line-height: 2em;
        background: $color-brand-01;
        padding: 0 $base-padding;
        margin: 0;
    }

    .form {
        padding: $base-padding;

        &.form-no-padding {
            padding: 0;
        }

        .switch {
            border-top: 1px solid #d1d4d7;

            label {
                padding: 10px;
            }

            &:nth-child(even) {
                background: #fafbfc;
            }

            &:nth-child(odd) {
                background: #fff;
            }

            .switch-small {
                margin: 0 13px;
                position: relative;
                top: 3px;
            }
        }
    }

    .actions {
        margin: $base-padding;
        display: flex;
        justify-content: flex-end;

        & > * {
            flex-basis: 25%;
            flex-shrink: 1;
        }
    }
}

.switch {
    display: flex;
    align-items: center;

    label {
        flex-grow: 2;
    }
}

.loader {
    border: 10px solid #f3f3f3;
    border-top: 10px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}

table.loading {
    thead {
        opacity: 0.1;
    }

    tbody {
        position: relative;
        height: 100%;
        overflow-y: auto;

        tr {
            opacity: 0.1;
        }

        .spinner {
            margin: 20px;
            position: absolute;
            height: 100%;
            width: 100%; //background-color: white;
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    flex-direction: column;
    width: 100%;

    &__info {
        margin-bottom: 0;
    }
}

.error-color {
    font-size: 12px;
    color: $red;
}

.success-message {
    color: $green;
    font-weight: bold;
    font-size: 14px;
}

.errors-list {
    li {
        padding-bottom: 10px;
    }
}

.drop-prompt {
    font-style: italic;
    font-weight: 100;
    color: rgba(0, 0, 0, 0.2);
}

.draggable-item-container {
    position: relative;

    .drag-handle {
        display: flex;
        align-items: center;
        justify-content: left;
        font-size: 14px;
        font-weight: 300;
        color: $font-color-main;

        .drag-bars-wrapper {
            flex-grow: 0;
            flex-basis: 5px;
            padding: 0 5px; //position: absolute;
            cursor: move;
            left: 0;
            display: block;

            .drag-bars {
                display: block;
                overflow: hidden;
                border-left: 1.5px solid rgba(0, 0, 0, 0.2);
                border-right: 1.5px solid rgba(0, 0, 0, 0.2);
                height: 1rem;
                width: 5px;
                margin-right: 10px;
            }
        }
    }
}

.dnd-drag-over {
    opacity: 0.4;
    border: 2px dashed #888;
    box-sizing: border-box;
}

.outlet-container {
    background: $white;
}

.container-fluid.no-gutters {
    padding: 0;
}

.tooltip-inner {
    white-space: pre-wrap;
}

.pretty-tooltip > div {
    animation-name: delayedFadeIn;
    animation-duration: 0.6s;

    /* Adjust this duration */
}

@keyframes delayedFadeIn {
    0% {
        opacity: 0;
    }

    75% {
        opacity: 0;
    }

    /* Set this to 99% for no fade-in. */
    100% {
        opacity: 1;
    }
}

@media only screen and (min-width: 1650px) {
    body {
        max-width: 1650px;
        margin: 0 auto;
    }
}

ngx-datatable {
    @include datatable();

    .datatable-header .datatable-header-cell .datatable-header-cell-label {
        vertical-align: middle;
        padding: 0 5px;

        @include table-header;
    }

    .datatable-header-cell {
        @include table-header;
    }

    .datatable-body .datatable-body-row .datatable-body-cell {
        overflow: visible;
    }

    label {
        &:after {
            transform: translate(0rem, -50%);
        }
    }
}

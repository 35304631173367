/* ###### 9.5 Misc   ###### */

// This generate a class name of .op-1 having a style of opacity: 0.1.
// Values goes up to 0.9;
$num: 0;

@while $num < 10 {
    .op-#{$num} { opacity: 0 + ($num * 0.1); }
    $num: $num + 1;
}

@mixin do-opacity($viewport) {
    $num: 0;

    @while $num < 10 {
        .op-#{$viewport}-#{$num} { opacity: 0 + ($num * 0.1); }
        $num: $num + 1;
    }
}

.outline-none { outline: none; }
.op-none { opacity: 1; }
.transition-base { @include transition($transition-base); }

.shadow-base { box-shadow: 0 0 15px rgba($gray-900, 0.06); }
.shadow-none { box-shadow: none; }
.shadow-none-f { box-shadow: none !important; }

.rotate-45 { transform: rotate(45deg); }
.rotate--45 { transform: rotate(-45deg); }
.rotate-90 { transform: rotate(90deg); }
.rotate--90 { transform: rotate(-90deg); }

.overflow-hidden { overflow: hidden; }
.overflow-y-auto { overflow-y: auto; }

// Flex
.flex-1 { flex: 1; }
.flex-0 { flex: 0; }

.img-fit-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.img-object-left { object-position: left; }
.img-object-top { object-position: top; }

.stroke-wd-2-5 { stroke-width: 2.5px; }
.stroke-wd-3 { stroke-width: 3px; }

@media (min-width: 480px) {
    @include do-opacity("xs");
}

@include media-breakpoint-up(sm) {
    @include do-opacity("sm");
}

@include media-breakpoint-up(md) {
    @include do-opacity("md");
}

@include media-breakpoint-up(lg) {
    @include do-opacity("lg");
}

@include media-breakpoint-up(xl) {
    @include do-opacity("xl");
}


.card {
    //box-shadow: 0 0 20px rgba($gray-900, .06);

    &.bg-primary,
    &.bg-secondary,
    &.bg-success,
    &.bg-warning,
    &.bg-danger,
    &.bg-info,
    &.bg-dark {
        border-width: 0;

        .card-header {
            background-color: transparent;
            border-bottom-color: rgba(#fff, 0.1);
        }
    }
}

.card-header,
.card-footer {
    background-color: transparent;
    border-color: $color-ui-border;
}

.card-header {
    padding: 15px;

    @include media-breakpoint-up(sm) {
        padding: 15px 20px;
    }
}

.card-body {
    height: 100%;
    padding: 15px;

    @include media-breakpoint-up(sm) { padding: 20px; }
}

.card-img { @include border-radius(); }
.card-img-top { @include border-top-radius($border-radius); }
.card-img-bottom { @include border-bottom-radius($border-radius); }


.tt-menu {
    min-width: 150px;
    background-color: #fff;
    border: $border;
    padding: 5px;

    @include border-bottom-radius($border-radius);

    box-shadow: 0 6px 8px 2px rgba($gray-900, 0.04);
}

.tt-suggestion {
    padding: 2px 10px;

    @include hover-focus() {
        cursor: pointer;
        background-color: $color-brand-01;
        color: #fff;
    }
}

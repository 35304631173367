
.badge {
    display: inline-flex;
    align-items: center;
    height: 22px;
    padding: 1px 5px 0;

    @include border-radius(3px);

    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
}

.badge-secondary {
    color: $white;
    background-color: #6c757d;
}

.badge-light {
    background-color: $gray-300;
}

.badge-pill {
    padding-left: 8px;
    padding-right: 8px;
}
